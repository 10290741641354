'<template>
	<div class="tsa-profile-form">
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<!-- user data -->
			<div>
				<div class="form-section">Contact Details</div>
				<div class="form-row with-label">
					<label>Status</label>
					<select v-model="form_data.status">
						<option value="active">Active</option>
						<option value="inactive">Inactive</option>
					</select>
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<!-- first name -->
				<div class="form-row with-label" :class="formRowClass('first_name')">
					<label>First Name</label>
					<input type="text" name="first_name" placeholder="Enter Your First Name" v-model="form_data.first_name" />
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<!-- last name -->
				<div class="form-row with-label" :class="formRowClass('last_name')">
					<label>Last Name</label>
					<input type="text" name="last_name" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
					<span class="error-block">{{ errors.last_name }}</span>
				</div>
				<!-- email -->
				<div class="form-row with-label" :class="formRowClass('email')">
					<label>Email</label>
					<input type="email" name="email" placeholder="Enter Your Email" v-model="form_data.email" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<!-- phone -->
				<div class="form-row with-label" :class="formRowClass('phone')">
					<label>Phone</label>
					<TelephoneField
						:phone="form_data.phone"
						:defaultCountry="form_data.phone_country_code"
						@country-changed="countryChanged"
						@phone-changed="onPhoneChanged"
						placeholder="Enter Phone Number" />
					<span class="error-block">{{ errors.phone }}</span>
				</div>
				<!-- country -->
				<div class="form-row with-label" :class="formRowClass('country_id')">
					<label>Country</label>
					<select v-model="form_data.country_id">
						<option value="">Choose Country</option>
						<option
							v-for="(country) in countries"
							:key="country.id"
							:value="country.id">{{ country.name }}</option>
					</select>
					<span class="error-block">{{ errors.country }}</span>
				</div>
				<!-- city -->
				<div class="form-row with-label" :class="formRowClass('city')">
					<label>City</label>
					<input type="text" name="city" placeholder="Enter Your City" v-model="form_data.city" />
					<span class="error-block">{{ errors.city }}</span>
				</div>
				<!-- role -->
				<div class="form-row with-label" :class="formRowClass('role')">
					<label>Role</label>
					<select v-model="form_data.role">
						<option value="">Choose One</option>
						<option
							v-for="(name,key) in roles"
							:key="key"
							:value="key">{{ name }}</option>
					</select>
					<span class="error-block">{{ errors.role }}</span>
				</div>
				<div class="form-row with-label" :class="formRowClass('type')">
					<label>User Type</label>
					<select v-model="form_data.type">
						<option value="">Choose One</option>
						<option value="basic">Basic User</option>
						<option value="admin">Administrator</option>
					</select>
					<span class="error-block">{{ errors.type }}</span>
				</div>
				<div class="form-row with-label" :class="formRowClass('type')">
					<label>Agreement Date</label>
					<input type="text" disabled v-model="form_data.agreement_date" />
				</div>
			</div>
			<div>
				<div v-if="!isEditMode && company.login_method != 'sso'">
					<div class='form-section'>Password Settings</div>
					<div class="with-label form-row" :class="formRowClass('password')">
						<label>Password</label>
						<input type="password" name="password" placeholder="Enter User Password" v-model="form_data.password" />
						<span class="error-block">{{ errors.password }}</span>
					</div>
					<div class="with-label form-row">
						<label>Confirm</label>
						<input type="password" name="password_confirmation" placeholder="Confirm Password" v-model="form_data.password_confirmation" />
						<span class="error-block">{{ errors.password_confirmation }}</span>
					</div>
					<div class="form-row tsa-note small text-left" style="margin-top: 20px;">
						<PasswordRequirements />
					</div>
				</div>
				<!-- community access (only edit) -->
				<div v-if="isEditMode" class="user-possible-relations">
					<div class="form-row">
						<div class="form-section">Community Access</div>
						<p class="page-instructions"><strong>NOTE:</strong> If checkbox is disabled, the company community relation specifies that all users have access to this community.</p>
						<div v-for="relation in communities"
							:key="relation.id"
							class="form-row">
								<input type="checkbox"
									:value="relation.id"
									:disabled="allUsersAccess(relation)"
									v-model="form_data.communities" />
								<label v-html="relationName(relation)" />
						</div>
					</div>

				</div>
			</div>
			<div>
				<div class='form-row'>
					<div class="uk-child-width-1-3 uk-grid-small" uk-grid>
						<div><button class='tsa-btn full-width' @click.prevent="saveUser">Save</button></div>
						<div><button class='tsa-btn full-width tsa-danger'
							v-if="isEditMode"
							@click.prevent="confirmDeleteUser">Delete</button></div>
						<div><button class='tsa-btn full-width tsa-default'
							v-if="isEditMode"
							@click.prevent="toggleStatus">{{ toggleStatusButtonText }}</button></div>
						<div><button class='tsa-btn full-width tsa-plain' @click.prevent="saveAndNew">Save &amp; New</button></div>
						<div class=" uk-width-expand"><button class='tsa-btn full-width tsa-btn-secondary'
							v-if="isEditMode"
							@click.prevent="updatePassword">Update Password</button></div>	
					</div>				
				</div>
			</div>			
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	import _ from 'lodash';
	import PasswordRequirements from '@/components/Layout/PasswordRequirements.vue';
	import TelephoneField from '@/components/Form/TelephoneField.vue';
	export default {
		name: 'user-form',
		components:{
			PasswordRequirements,
			TelephoneField
		},
		props: {
			user: {
				type: Object,
				default: () => {}
			},
			access: {
				type: Array,
				default: () => []
			},
			company : {
				type : Object,
				default : () => {}
			},
			form_ready: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				v_phone : "",
				form_data: {
					status: "active",
					first_name: "",
					last_name: "",
					email: "",
					phone: "",
					phone_country_code: "",
					country_id: "",
					city: "",
					role: "",
					type: "basic",					
					password: "",
					password_confirmation: "",
					communities: []
				},
				community_access:[],
				errors: {},
				roles: [],
				communities: [],
				countries: [],
				modal: {
					open: true
				},
				save_and_new: false,
				company_id: null
			}
		},
		mounted(){
			
			this.initialize();
		},
		updated(){
			this.initialize();
		},
		/**
         * remove bus event listeners before component is destroyed.
         * @return {[type]} [description]
         */
        beforeUnmount(){
			this.emitter.off('sa_details_confirm_delete_user');
			this.emitter.off('token_ready');
        },
		methods: {
			initialize(){
				if(this.has_initialized) return;
				this.has_initialized = true;
				this.emitter.on('token_ready', () => this.getData());
				this.emitter.on('sa_details_confirm_delete_user', () => this.deleteUser());
				if(this.jwt_token.access_token != undefined) this.getData();
			},
			getData(){
				//try to get it myself
				if(this.company_id === null && this.$route.params.company_id !== undefined){
					this.company_id = this.$route.params.company_id;
				}
				if(this.company_id !== null){
					// eslint-disable-next-line
					axios.all([
						// eslint-disable-next-line
						axios.get(`${process.env.VUE_APP_API_URL}super_admin/users/form_data/${this.company_id}`),
						// eslint-disable-next-line
						axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company_id}/groups`),
					])
						// eslint-disable-next-line
						.then(axios.spread((data_response, groups_response ) => {
							this.onFormData(data_response.data);
							this.onGroupsLoaded(groups_response.data);
						}))
						//eslint-disable-next-line
						.catch(error => console.log(error));
				}
			},
			/**
			 * save data
			 * @return {[type]} [description]
			 */
			onFormData(response){
				this.countries = response.countries;
				this.roles = response.roles;
			},
			onGroupsLoaded(response){
				this.communities = response;
				this.handleDefaultSelectedCommunities();
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * clone user into form data.
			 * @return {[type]} [description]
			 */
			cloneUser(){
				let data = _.clone(this.user);
				data.communities = this.form_data.communities;
				this.form_data = data;
				this.handleDefaultSelectedCommunities();
			},
			handleDefaultSelectedCommunities(){
				let selected = [];
				this.communities.forEach(relation => {
					if(this.allUsersAccess(relation) || this.userHasAcessToCommunity(relation)){
						selected.push(relation.id);
					}
				});
				this.form_data.communities = selected;
			},
			/**
			 * loop through received access
			 * add create array bound to access checkboxes
			 */
			setDefaultAccess(){
				let commAccess = [];
				for(let i in this.access){
					const a  = this.access[i];
					if(a.access) commAccess.push(a.community_id);
				}
				this.community_access = commAccess;
			},
			saveUser(){
				this.save_and_new = false;
				this.saveRequest();
			},
			saveAndNew(){
				this.save_and_new = true;
				this.saveRequest();
			},
			/**
			 * send request to update user data.
			 * @return {[type]} [description]
			 */
			saveRequest(){
				// clear all errors
				this.errors = {};
				this.emitter.emit('loader',true);

				let data = _.clone(this.form_data);
				data.company_id = this.$route.params.company_id;

				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/users/${this.$route.params.user_id}`,data)
					.then(response => this.handleSaveUser(response.data))
					// eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * handle update user response
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSaveUser(response){

				this.emitter.emit('loader',false);
				this.emitter.emit('alert',response.message);
				// let parent component user was updated.
				if(this.save_and_new){
					// stay on current page so user can create another
					this.resetFormData();
				}
				// let parent component user was updated.
				this.$emit('updated',{
					user: response.user,
					save_and_new: this.save_and_new
				});
				
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * check if all users have access to this community relation.
			 * @param  {object} relation CompanyCommunity record
			 * @return {[type]}          [description]
			 */
			allUsersAccess(relation){
				return (relation.user_access_flag == "all");
			},
			userHasAcessToCommunity(relation){
				let access = false;
				for(let i in this.user.group_access){
					if(this.user.group_access[i].community_id == relation.community_id){
						access = true;
						break;
					}
				}
				return access;
			},
			/**
			 * confirm that user wants to delete the user.
			 * @return {[type]} [description]
			 */
			confirmDeleteUser(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete ${this.user.first_name} ${this.user.last_name} from the system?`,
					flag: "sa_details_confirm_delete_user"
				});
			},
			/**
			 * send request to delete user
			 * @return {[type]} [description]
			 */
			deleteUser(){
				this.emitter.emit('loader',true);
				const url = `${process.env.VUE_APP_API_URL}super_admin/users/${this.user.id}/delete`;
				//eslint-disable-next-line
				axios.get(url)
				.then(response => this.handleDeleteUser(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * if error alert error
			 * otherwise success message
			 * @return {[type]} [description]
			 */
			handleDeleteUser(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.emitter.emit('alert',response.message);
					this.$router.push({name:"super_admin.company.detail",params: {company_id: this.$route.params.company_id }});
				}
			},
			/**
			 * send request to toggle status
			 * @return {[type]} [description]
			 */
			toggleStatus(){
				this.emitter.emit('loader',true);
				const data = {
					status : (this.user.status == "active")? "inactive" : "active"
				};
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/users/${this.user.id}/status`,data)
					.then(response => this.handleToggleStatus(response.data))
					//eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * let user know that status was changed.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleToggleStatus(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.$emit('updated',response.user);
					this.emitter.emit('alert',response.message);
				}
			},
			updatePassword(){
				this.$emit('password');
			},
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
					this.form_data.phone_country_code = data;
				}else{
					// object
					this.form_data.phone_country_code = data.iso2;
				}
			},
			onPhoneChanged(number){
				this.form_data.phone = number;
			},
			/**
			 * company | department (if available)
			 * @param  {[type]} relation [description]
			 * @return {[type]}          [description]
			 */
			relationName(relation){
				let name = "";
				if(relation.community) { name = relation.community.name;}
				if(relation.department != null) name += ` | <span>${relation.department.name}</span>`;
				return name;
			},
			/**
			 * reset form data.
			 * @return {[type]} [description]
			 */
			resetFormData(){
				this.form_data = {
					status: "active",
					first_name: "",
					last_name: "",
					email: "",
					phone: "",
					phone_country_code: "",
					country_id: "",
					city: "",
					role: "",
					type: "basic",					
					password: "",
					password_confirmation: "",
					communities: []
				};
			}
		},
		watch:{
			user(){
				this.cloneUser();
				this.company_id = this.user.company_id;
				this.getData();
			},
			access(){
				this.setDefaultAccess();
			}
		},
		computed:{
			toggleStatusButtonText(){
				return (this.user.status == "active")? "Disable" : "Enable";
			},
			userStatus(){
				if(this.user.status == undefined) return "";
				return this.user.status.ucfirst();
			},
			isEditMode(){
				return this.user.id != undefined;
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>